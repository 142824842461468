import React from "react";

import Image from "./Image";
import LinkButtonInternal from "../components/LinkButtonInternal";

const BlogCard = ({ blogInfo }) => {
  return (
    <div key={blogInfo.id} className="px-12 md:px-0 lg:max-w-[350px] blogcard">
      {blogInfo.featuredImage && (
        <Image
          image={blogInfo.featuredImage.node}
          alt={blogInfo.title}
          className={`filter grayscale transition-all duration-300 mb-4 w-full max-w-[350px] h-[230px] object-cover`}
        />
      )}
      <h3 className="font-bold text-darkblue text-xl mb-4">{blogInfo.title}</h3>
      <LinkButtonInternal
        color="blue"
        label={`Read more`}
        link={`/news${blogInfo.uri}`}
      />
    </div>
  );
};

export default BlogCard;
