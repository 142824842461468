import React from "react";

const Service = ({ serviceLabel }) => {
  return (
    <p className="font-bold text-3xl text-center mt-[25px] lg:mt-0 w-full md:w-auto">
      <a href="/our-services">{serviceLabel.label}</a>
    </p>
  );
};

export default Service;
