import React from "react";

import LinkButton from "./LinkButton";
import Slider from "./Slider";
import Ampersand from "./Ampersand";

const Hero = ({ isLight, panel, panelLink, slider }) => {
  return (
    <div className="relative overflow-hidden">
      <Slider slides={slider} />
      <div
        className={`absolute z-20 opacity-50 h-[40%] bg-gradient-to-b from-black to-transparent top-0 left-0 w-screen`}
      ></div>
      <div className="2xl:container w-full hidden pl-28 lg:block mx-auto">
        <div className="absolute z-30 uppercase top-[240px] transform text-5xl leading-tight font-bold text-white text-left">
          Independent <br />
          <Ampersand />
          Impartial
        </div>
      </div>
      <div className="2xl:container relative mx-auto">
        <div className="absolute z-30 uppercase lg:hidden left-12 md:bottom-auto top-[-85px] transform -translate-y-1/2 md:top-[-600px] md:left-28 text-4xl md:text-5xl leading-tight font-bold text-white text-left">
          Independent <br />
          <Ampersand />
          Impartial
        </div>
        <div className="md:absolute bottom-0 left-0 z-10 max-w-[480px] py-12 px-12 text-white bg-gradient-to-b from-cerulean-500 to-cerulean-700">
          <div
            className="mb-8"
            dangerouslySetInnerHTML={{ __html: panel }}
          ></div>
          <LinkButton link={panelLink.url} label={panelLink.title} />
        </div>
      </div>
    </div>
  );
};

export default Hero;
