import React from "react";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";

import { GetLatestPosts } from "../queries/latestposts";
import BlogCard from "./BlogCard";

const SliderBlog = () => {
  SwiperCore.use([Navigation]);
  const latest = GetLatestPosts();
  const latestPosts = latest;
  return (
    <Swiper
      className="w-full"
      loop={false}
      navigation={{
        prevEl: ".prev",
        nextEl: ".next",
      }}
      centeredSlides={false}
      breakpoints={{
        0: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 50,
        },
      }}
      onSlideChange={() => {}}
      onSwiper={(swiper) => {}}
    >
      {latestPosts.map((item) => {
        const post = item.node;
        return (
          <SwiperSlide key={post.id}>
            <BlogCard blogInfo={post} />
          </SwiperSlide>
        );
      })}
      <div className="prev lg:hidden transform translate -translate-y-1/2	 cursor-pointer text-cerulean absolute top-[50%] left-1 z-10 border-cerulean border-2 rounded-2xl px-3 inline-block">
        &larr;
      </div>
      <div className="next lg:hidden transform translate -translate-y-1/2	 cursor-pointer text-cerulean absolute top-[50%] right-1 z-10 border-cerulean border-2 rounded-2xl px-3 inline-block">
        &rarr;
      </div>
    </Swiper>
  );
};

export default SliderBlog;
