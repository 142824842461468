import React from "react";
import SliderBlog from "./SliderBlog";

const NewsPanel = ({ heading }) => {
  return (
    <div className="bg-darkblue-100 py-24 lg:p-32">
      <div className="container">
        <h3 className="lg:text-indent--4 text-darkblue text-5xl leading-tight px-12 lg:px-0 font-bold mb-12 uppercase">
          {heading}
        </h3>
        <SliderBlog />
      </div>
    </div>
  );
};

export default NewsPanel;
