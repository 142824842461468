import React from "react";
import { Link } from "gatsby";
import { BsArrowRight } from "react-icons/bs";

const LinkButtonInternal = ({ link, label, color }) => {
  return (
    <Link
      to={link}
      className={`group flex border-2 overflow-hidden relative rounded-2xl transition duration-200 px-4 py-1 text-sm justify-between items-center max-w-[200px] btn ${
        color === "blue"
          ? "text-cerulean border-cerulean hover:bg-cerulean hover:text-white"
          : "hover:text-cerulean-900"
      }`}
    >
      <span
        className={`link-bg absolute transition-all duration-300 -left-full h-full w-full group-hover:left-0 ${
          color === "blue" ? "bg-cerulean" : "bg-white"
        }`}
      ></span>
      <span className="z-10 transition duration-200 flex justify-between w-full">
        {label}
        <BsArrowRight className="text-xl" />
      </span>
    </Link>
  );
};

export default LinkButtonInternal;
