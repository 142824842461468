import React from "react";

import Service from "./Service";
import LinkButtonInternal from "./LinkButtonInternal";

const Services = ({ servicesData }) => {
  return (
    <div className="container py-24 px-12 lg:p-24 text-center text-darkblue">
      <h3 className="uppercase text-darkblue text-5xl leading-tight font-bold mb-12">
        {servicesData.heading}
      </h3>
      <p className="max-w-lg mx-auto lg:mb-12">{servicesData.body}</p>
      <div className="flex flex-wrap flex-col lg:flex-row max-w-[900px] mx-auto justify-between mb-12">
        {servicesData.services.map((service, i) => {
          return <Service key={i} serviceLabel={service} />;
        })}
      </div>
      <div className="flex justify-center">
        <LinkButtonInternal
          color="blue"
          link={servicesData.link.url}
          label={servicesData.link.title}
        />
      </div>
    </div>
  );
};

export default Services;
