import { useStaticQuery, graphql } from "gatsby";
export const GetLatestPosts = () => {
  const latestposts = useStaticQuery(
    graphql`
      query LatestPosts {
        wp {
          posts(first: 3, where: { orderby: { field: DATE, order: DESC } }) {
            edges {
              node {
                id
                title
                uri
                excerpt
                date
                featuredImage {
                  node {
                    ...MediaItem
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return latestposts.wp.posts.edges;
};
