import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Hero from "../components/Hero";
import Services from "../components/Services";
import NewsPanel from "../components/NewsPanel";

export const pageQuery = graphql`
  {
    wp {
      page(id: "/home/", idType: URI) {
        id
        title
        pageHome {
          services {
            heading
            body
            link {
              title
              url
              target
            }
            services {
              label
            }
          }
          news {
            heading
          }
          hero {
            heading
            panel
            panelLink {
              title
              url
              target
            }
            slider {
              ...MediaItem
            }
          }
        }
      }
    }
  }
`;

const indexPage = ({ data }) => {
  const page = data.wp.page;
  const fields = page.pageHome;
  return (
    <Layout title={page.title} themeStyle={true}>
      {/* Hero */}
      {fields.hero && <Hero {...fields.hero} />}
      {fields.services && <Services servicesData={fields.services} />}
      {/* Dont name prop "props" as that's all the props */}
      <NewsPanel heading={fields.news.heading} />
    </Layout>
  );
};

export default indexPage;
