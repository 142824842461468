import React from "react";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";

import Image from "./Image";

const Slider = ({ slides }) => {
  SwiperCore.use([Autoplay]);

  return (
    <Swiper
      className="w-full"
      loop={false}
      speed={750}
      autoplay={{ delay: 4000 }}
      spaceBetween={0}
      slidesPerView={1}
      onSlideChange={() => {}}
      onSwiper={(swiper) => {}}
    >
      {slides.map((slide, i) => {
        return (
          <SwiperSlide key={i} className="filter grayscale">
            <Image
              image={slide}
              alt={slide.altText}
              className={`max-h-screen h-[450px] md:h-[100vh] block object-cover w-full`}
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default Slider;
